import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/home1/Hero";
import CTA from "../sections/home2/CTA";

const Home5 = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu",
          headerFluid: true,
          footerStyle: "style5",
        }}
      >
        <Hero className="position-relative pt-20 pt-sm-24 pt-lg-28 pb-lg-32" />
        <CTA className="bg-images" />
       </PageWrapper>
    </>
  );
};
export default Home5;
